/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, Reducer, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { SubmitButton, Button } from "../../../theme";
import { AdvancedInput, CompanyNotFound, DeleteItem } from "../../";
import { ProgramReducer, INITIAL_PROGRAM_STATE } from "../../../utilities";
import { DataContext } from "../../../context/DataContext";
import {
  CREATE_PROGRAM,
  UPDATE_PROGRAM,
  GET_ALL_PROGRAMS,
  DELETE_PROGRAM,
  CREATE_UPDATE,
} from "../../../api";

const AddProgram = (props: any) => {
  const id = props.id;
  const editData = props.data;
  const { companies, contacts, industries, users } =
    React.useContext(DataContext);
  const [createNewProgram] = useMutation(CREATE_PROGRAM);
  const [updateProgram] = useMutation(UPDATE_PROGRAM);
  const [createUpdate] = useMutation(CREATE_UPDATE);
  const [stage, setStage] = useState(1);
  const [input, setInput] = useState(null);
  const [error, setError] = useState(null);
  const [successfullSubmit, setSuccessfullSubmit] = useState(false);
  const [funders, setFunders] = useState([]);
  const [state, dispatch] = useReducer<Reducer<any, any>, any>(
    ProgramReducer,
    INITIAL_PROGRAM_STATE(),
    undefined
  );

  useEffect(() => {
    if (companies) {
      const funders = companies.filter(
        (item) => item.isFunder === true && item.status === "Active"
      );
      setFunders(funders);
    }
  }, []);

  useEffect(() => {
    const getInputState = async () => setInput(await state);
    getInputState();
  });

  const handlePrevStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage - 1);
  };

  const handleNextStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage + 1);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!props.isEdit && !input.name) {
      formIsValid = false;
      errors["name"] = "name can not be empty";
    }
    if (input.description.length > 500) {
      formIsValid = false;
      errors["description"] =
        "Description can not be longer than 500 charactors";
    }
    setError({ errors: errors });
    return formIsValid;
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (handleValidation()) {
      if (props.isEdit) {
        const updatedInputs = JSON.parse(
          JSON.stringify(input, (key, value) => {
            if (Array.isArray(value) && value.length === 0) {
              return undefined;
            } else if (value === null || value === "") {
              return undefined;
            } else {
              return value;
            }
          })
        );
        updateProgram({
          variables: { id, input: updatedInputs },
          refetchQueries: [{ query: GET_ALL_PROGRAMS }],
        });
        createUpdate({
          variables: {
            sourceId: id,
            newUpdate: {
              userId: props.user?.attributes.sub,
            },
          },
        });
        toast.success("Program has successfully been updated");
      } else {
        const newProgramInput = { ...input, status: "Pending" };
        createNewProgram({
          variables: { newProgram: newProgramInput },
          refetchQueries: [{ query: GET_ALL_PROGRAMS }],
        });
        toast.success(
          "Program has successfully been added. You can close the window"
        );
        setSuccessfullSubmit(true);
      }
    } else {
      toast.error(
        "OOPS! that didn't work. Please verify the information and try again"
      );
      setStage(1);
    }
    console.log(input);
  };

  return (
    <form className="add-item-form" onSubmit={handleSubmit}>
      {stage === 1 && (
        <>
          <AdvancedInput
            {...props}
            name="Program Name"
            type="text"
            width="95%"
            isRequired={props.isEdit ? false : true}
            placeholder={editData?.name}
            value={state.name}
            onChange={(e) =>
              dispatch({ field: "name", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.name}</span>
          <AdvancedInput
            {...props}
            name="tagline"
            type="text"
            width="95%"
            placeholder={editData?.tagLine}
            value={state.tagLine}
            onChange={(e) =>
              dispatch({ field: "tagLine", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Funder"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={funders}
            value={state.funder}
            onChange={(selectedOptions) =>
              dispatch({
                field: "funder",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <CompanyNotFound isFunder />
          <AdvancedInput
            {...props}
            name="main funder contacts"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={contacts}
            value={state.contacts}
            onChange={(selectedOptions) =>
              dispatch({
                field: "contacts",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="Dovico Code"
            type="text"
            width="95%"
            value={state.code}
            onChange={(e) =>
              dispatch({ field: "code", payload: e.target.value })
            }
          />
        </>
      )}
      {stage === 2 && (
        <>
          <AdvancedInput
            {...props}
            name="start date"
            type="date"
            width="95%"
            value={state.startDate}
            onChange={(e) =>
              dispatch({ field: "startDate", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="end date"
            type="date"
            width="95%"
            value={state.dueDate}
            onChange={(e) =>
              dispatch({ field: "dueDate", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Description"
            type="textArea"
            width="95%"
            value={state.description}
            onChange={(e) =>
              dispatch({ field: "description", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.description}</span>
        </>
      )}
      {stage === 3 && (
        <>
          <AdvancedInput
            {...props}
            name="Program Lead"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={users}
            value={state.lead}
            onChange={(selectedOptions) =>
              dispatch({
                field: "lead",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="specialists"
            type="dropdown"
            width="95%"
            fieldName="name"
            isMulti
            data={users}
            value={state.members}
            onChange={(selectedOptions) =>
              dispatch({
                field: "members",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="industries"
            data={industries}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            isRequired={false}
            value={state.industries}
            onChange={(selectedOptions) =>
              dispatch({
                field: "industries",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
        </>
      )}
      <div className="add-item-button-container">
        {stage === 1 ? (
          <div style={{ width: "150px" }} />
        ) : (
          <Button width="150px" onClick={handlePrevStage}>
            Go Back
          </Button>
        )}
        {stage !== 3 ? (
          <Button width="150px" onClick={handleNextStage}>
            Next
          </Button>
        ) : successfullSubmit ? (
          <Button width="150px" disabled={successfullSubmit}>
            Success
          </Button>
        ) : (
          <SubmitButton width="150px" disabled={successfullSubmit}>
            Submit
          </SubmitButton>
        )}
      </div>
      {stage === 3 && props.isEdit ? (
        <DeleteItem
          id={id}
          user={props.user}
          mutation={DELETE_PROGRAM}
          refetch={GET_ALL_PROGRAMS}
          type="Program"
        />
      ) : null}
    </form>
  );
};

export default AddProgram;
