import React from "react";
import Amplify, { Auth } from "aws-amplify";
import { Route, Routes } from "react-router-dom";
import AppSyncProvider from "./aws/ApolloConfig";
import { ToastContainer } from "react-toastify";
import { Sidebar, Header, Breadcrumbs, IdleSession } from "./components";
import {
  Dashboard,
  Login,
  Loading,
  AdminDashboard,
  AdminDepartments,
  AdminCategories,
  AdminCertification,
  AdminContactTypes,
  AdminInteractionCategory,
  AdminInteractionType,
  AdminResourceTypes,
  AdminServiceTypes,
  AdminIndustries,
  AdminUsers,
  Contact,
  Contacts,
  Company,
  Companies,
  Programs,
  Program,
  Projects,
  Project,
  Resource,
  Resources,
  Services,
  Service,
  Error,
  FAQ,
} from "./pages";
import awsconfig from "./aws-exports";
import "./theme/_main.scss";
import DataProvider from "./context/DataContext";

// Amplify Config
Amplify.configure(awsconfig);

const App = () => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [isAuthenticated, setIsAuthenticated] = React.useState<any>(null);
  const [user, setUser] = React.useState<any>(null);

  React.useEffect(() => {
    const checkAuthState = async () => {
      try {
        const authedUser = await Auth.currentAuthenticatedUser();
        if (authedUser) {
          const userHasNoMFA = authedUser.preferredMFA === "NOMFA";

          if (userHasNoMFA) {
            await Auth.signOut();
            window.location.href = "/";
          } else {
            setUser(authedUser);
            setIsAuthenticated(true);
          }
        } else {
          window.location.href = "/";
        }
      } catch (err) {
        console.log(err);
      }
    };
    checkAuthState();
  }, []);

  React.useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <AppSyncProvider>
      <DataProvider>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {!loading ? (
          <div
            className="App"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "/images/app-bg.svg"
              })`,
            }}
          >
            {isAuthenticated ? (
              <>
                <Header user={user} />
                <Breadcrumbs />
                <Sidebar />
                <IdleSession />
                <div className="framed-route-container">
                  <Routes>
                    <Route path="/" element={<Dashboard user={user} />} />
                    <Route path="/FAQ" element={<FAQ user={user} />} />
                    {/* Contact Routes */}
                    <Route
                      path="/directory/contacts/:id"
                      element={<Contact user={user} />}
                    />
                    <Route
                      path="/directory/contacts"
                      element={<Contacts user={user} />}
                    />
                    {/* Company Routes */}
                    <Route
                      path="/directory/companies/:id"
                      element={<Company user={user} />}
                    />
                    <Route
                      path="/directory/companies/"
                      element={<Companies user={user} />}
                    />
                    {/* Program Routes */}
                    <Route
                      path="/directory/programs/:id"
                      element={<Program user={user} />}
                    />
                    <Route
                      path="/directory/programs/"
                      element={<Programs user={user} />}
                    />
                    {/* Project Routes */}
                    <Route
                      path="/directory/projects/:id"
                      element={<Project user={user} />}
                    />
                    <Route
                      path="/directory/projects/"
                      element={<Projects user={user} />}
                    />
                    {/* Resource Routes */}
                    <Route
                      path="/directory/resources/:id"
                      element={<Resource user={user} />}
                    />
                    <Route
                      path="/directory/resources/"
                      element={<Resources user={user} />}
                    />
                    {/* Services Routes */}
                    <Route
                      path="/directory/services/:id"
                      element={<Service user={user} />}
                    />
                    <Route
                      path="/directory/services/"
                      element={<Services user={user} />}
                    />
                    {/* Admin Routes */}
                    <Route path="/admin" element={<AdminDashboard />} />
                    <Route
                      path="/admin/categories-edit"
                      element={<AdminCategories />}
                    />
                    <Route
                      path="/admin/industries-edit"
                      element={<AdminIndustries />}
                    />
                    <Route
                      path="/admin/departments-edit"
                      element={<AdminDepartments />}
                    />

                    <Route
                      path="/admin/contact-types-edit"
                      element={<AdminContactTypes />}
                    />
                    <Route
                      path="/admin/certifications-edit"
                      element={<AdminCertification />}
                    />
                    <Route
                      path="/admin/interaction-category-edit"
                      element={<AdminInteractionCategory />}
                    />
                    <Route
                      path="/admin/interaction-type-edit"
                      element={<AdminInteractionType />}
                    />
                    <Route
                      path="/admin/resource-types-edit"
                      element={<AdminResourceTypes />}
                    />
                    <Route
                      path="/admin/service-types-edit"
                      element={<AdminServiceTypes />}
                    />
                    <Route path="/admin/users-edit" element={<AdminUsers />} />
                    <Route path="*" element={<Error type="no-route" />} />
                  </Routes>
                </div>
              </>
            ) : (
              <Routes>
                <Route path="/" element={<Login />} />
              </Routes>
            )}
          </div>
        ) : (
          <Loading />
        )}
      </DataProvider>
    </AppSyncProvider>
  );
};

export default App;
