/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { SEARCH_COMPANIES, GET_USERS } from "../../api";
import { Editor, AdvancedInput } from "..";
import { Status } from "../../utilities";
import ItemUpdate from "../item-updates/ItemUpdate";
import * as FaIcons from "react-icons/fa";
import { SubmitButton } from "../../theme";

const ProgramOverview = (props: any) => {
  const Data = props.data;
  const { data: companyData } = useQuery(SEARCH_COMPANIES);
  const { data: userData } = useQuery(GET_USERS);
  const [companies, setCompanies] = useState(null);
  const [lead, setLead] = useState([]);
  const [members, setMembers] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [status, setStatus] = useState("");

  // Get Related Pairings
  // Funder & Companies
  useEffect(() => {
    if (!Data?.funder || Data.funder.length === 0) {
      setCompanies([]);
    } else {
      const linkedFunders = companyData?.getAllCompanies.companies.filter(
        (item) => item && Data.funder.includes(item.id)
      );
      setCompanies(linkedFunders);
    }
  }, [companyData, Data?.funder]);

  // Users and Lead
  useEffect(() => {
    const linkedLead = userData?.getAllUsers.users.filter((item) => {
      if (Data?.lead.includes(item.id)) return item;
    });
    setLead(linkedLead);

    const linkedMembers = userData?.getAllUsers.users.filter((item) => {
      if (Data?.members.includes(item.id)) return item;
    });
    setMembers(linkedMembers);
  }, [userData, Data?.lead, Data?.members]);

  const handleStatusUpdate = (e) => {
    e.preventDefault();
    props.handleStatusUpdate(status);
    setEditStatus(!editStatus);
  };

  return (
    <div className="item-overview">
      <div className="item-main-info">
        <p className="item-name">{Data?.name}</p>
        {props.type !== "resource" && (
          <>
            <p>
              Tagline:
              <span>{Data?.tagLine}</span>
            </p>
            <ul>
              {props.type} Code:
              <li>{Data?.code}</li>
            </ul>
          </>
        )}
        <ul>
          Funder:
          {companies?.map((item) => (
            <li key={item.id}>
              <Link to={`/directory/companies/${item?.id}`}>{item?.name}</Link>
            </li>
          ))}
        </ul>
        <ul>
          Lead:
          {lead?.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
        <ul>
          Specialists
          {members?.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
        <ul>
          Description:
          <li>{Data?.description}</li>
        </ul>
      </div>
      <div className="item-dates-info">
        <div className="status">
          <p>
            Status:
            <span>{Data?.status}</span>
          </p>
          {props.managerPermission && (
            <button onClick={() => setEditStatus(!editStatus)}>
              <FaIcons.FaEdit />
            </button>
          )}
        </div>
        {editStatus && (
          <Editor
            type="Status"
            component={[
              <form className="add-item-form">
                <AdvancedInput
                  {...props}
                  name="Status"
                  type="dropdown"
                  width="95%"
                  fieldName="name"
                  data={Status}
                  value={status}
                  onChange={(e) => setStatus(e.name)}
                />
                <div className="add-item-button-container">
                  <div />
                  <SubmitButton width="150px" onClick={handleStatusUpdate}>
                    Submit
                  </SubmitButton>
                </div>
              </form>,
            ]}
          />
        )}
        <p>
          Start Date:
          <span>{Data?.startDate}</span>
        </p>
        <p>
          Due Date:
          <span>{Data?.dueDate}</span>
        </p>
        <ItemUpdate data={Data} isManager={props.isManager} />
      </div>
    </div>
  );
};

export default ProgramOverview;
