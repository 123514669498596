import React from "react";
import { AgGridReact } from "ag-grid-react";
import useGetUserFullName from "../../utilities/hooks/useGetUserFullName";
import InfoCard from "../info-card/InfoCard";
import { IdToNameFormatter } from "../../utilities";
import { DataContext } from "../../context/DataContext";
import * as AiIcons from "react-icons/ai";

type ItemUpdateProps = {
  data: any;
  isManager: boolean;
};

const ItemUpdate = ({ data, isManager }: ItemUpdateProps) => {
  const gridRef = React.useRef(null);
  const { users } = React.useContext(DataContext);
  const [userId, setUserId] = React.useState<string | undefined>("");
  const [showList, setShowList] = React.useState<boolean>(false);
  const [updates, setUpdates] = React.useState<any[]>([]);
  const [gridApi, setGridApi] = React.useState(null);
  const userFullName = useGetUserFullName(userId);
  const [columnDefs] = React.useState([
    {
      field: "formattedUserName.name",
      headerName: "Team Member",
      width: 165,
      minWidth: 165,
    },
    {
      field: "formattedDate",
      headerName: "Date",
      width: 105,
      minWidth: 105,
    },
  ]);

  // Get name of user who last updated item
  React.useEffect(() => {
    if (data?.updates) {
      setUserId(data?.updates[0]?.userId);
    }
  }, [data]);

  // Set table data
  React.useEffect(() => {
    if (data?.updates) {
      setUpdates(
        data?.updates.map((item: any) => {
          const formattedUserName = item.userId
            ? IdToNameFormatter({
                data: users,
                fieldToMatch: item.userId,
              })
            : "";
          return {
            ...item,
            formattedUserName,
            formattedDate: item.createdAt?.slice(0, 10),
          };
        })
      );
    }
  }, [data, users]);

  // Table auto-scaler
  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };

  React.useEffect(() => {
    if (gridApi) {
      gridApi.sizeColumnsToFit();
    }
  });

  const handleExport = React.useCallback(() => {
    gridRef.current.api.exportDataAsExcel({
      allColumns: true,
    });
  }, []);

  return (
    <>
      <div className="item-update">
        <div className="header">
          <p>Last Modified:</p>
          {isManager && (
            <button onClick={() => setShowList(!showList)}>View all</button>
          )}
        </div>
        {data?.updates && data?.updates.length > 0 ? (
          <div className="update">
            <p>
              By: <span>{userFullName}</span>
            </p>
            <p>
              On: <span>{data?.updates[0]?.createdAt?.substr(0, 10)}</span>
            </p>
          </div>
        ) : (
          <p>No Recent Updates</p>
        )}
      </div>
      {showList && (
        <div className="item-update-list">
          <InfoCard
            headerOption="modal"
            title="Latest Updates"
            onBtnClick={() => setShowList(false)}
            width="350px"
            height="468px"
            backgroundColor="white"
            fields={
              <div className="item-update-list-table-container">
                <div className="items-table-header">
                  <div className="items-table-button-container">
                    <div />
                    <p title="export">
                      <AiIcons.AiOutlineDownload onClick={handleExport} />
                    </p>
                  </div>
                </div>
                <div className="ag-theme-alpine" id="item-update-list-table">
                  <AgGridReact
                    ref={gridRef}
                    rowData={updates}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    suppressRowClickSelection={true}
                  />
                </div>
              </div>
            }
          />
        </div>
      )}
    </>
  );
};

export default ItemUpdate;
