import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PageBody, PageGrid } from "../../theme";
import { GET_RESOURCE_BY_ID } from "../../api";
import {
  AddResource,
  Editor,
  InfoCard,
  ResourceOverview,
  Notes,
} from "../../components";
import { ResourceReducer } from "../../utilities";

const Resource = (props: any) => {
  const { id } = useParams();
  const [resource, setResource] = useState(null);
  const [created, setCreated] = useState("");
  const [editor, setEditor] = useState(false);
  const [managerStatus, setManagerStatus] = useState(false);
  const { data: resourceData } = useQuery(GET_RESOURCE_BY_ID, {
    variables: { id },
  });

  useEffect(() => {
    if (resourceData) {
      setResource(resourceData?.getResourceById);
      setCreated(resource?.createdAt.substr(0, 10));
    }
  }, [resourceData, resource?.createdAt]);

  useEffect(() => {
    if (
      props?.user.attributes["custom:userGroup"] === "Uber User" ||
      props?.user.attributes["custom:userGroup"] === "Finance"
    ) {
      setManagerStatus(true);
    }
  }, [props?.user]);

  const handleEdit = (e) => {
    e.preventDefault();
    setEditor(!editor);
  };

  return (
    <>
      {editor && (
        <Editor
          type="Resource"
          component={
            <AddResource {...props} isEdit={true} id={id} data={resource} />
          }
        />
      )}
      <PageBody>
        <div className="last-modified">
          <p>
            Last Modified:
            <span>{created}</span>
          </p>
        </div>
        <PageGrid>
          <div className="item-left-column resource">
            <InfoCard
              title="Overview"
              height="850px"
              width="100%"
              headerOption="edit"
              onBtnClick={handleEdit}
              fields={[
                <ResourceOverview
                  data={resource}
                  type="resource"
                  isManager={managerStatus}
                />,
              ]}
            />
          </div>
          <div className="item-right-column resource">
            <InfoCard
              title="Notes"
              height="850px"
              width="100%"
              fields={[
                <Notes
                  id={id}
                  data={resource?.notes}
                  reducer={ResourceReducer}
                  refetchQuery={GET_RESOURCE_BY_ID}
                />,
              ]}
            />
          </div>
        </PageGrid>
      </PageBody>
    </>
  );
};

export default Resource;
