import { gql } from "@apollo/client";

export const GET_COMPANIES = gql`
  query getAllCompanies($nextToken: String) {
    getAllCompanies(nextToken: $nextToken) {
      companies {
        id
        name
        phone
        fax
        email
        website
        certifications
        isFunder
        industries
        contactTypes
        specialist
        categories
        country
        province
        county
        city
        street
        street2
        postal
        status
        createdAt
        updatedAt
        createdBy
      }
      nextToken
    }
  }
`;

export const GET_COMPANY_BY_ID = gql`
  query getCompanyById($id: ID!) {
    getCompanyById(id: $id) {
      id
      name
      phone
      fax
      email
      website
      certifications
      isFunder
      industries
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      createdAt
      createdBy
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const CREATE_COMPANY = gql`
  mutation createCompany($newCompany: CompanyInput!) {
    createCompany(newCompany: $newCompany) {
      id
      name
      phone
      fax
      email
      website
      certifications
      isFunder
      industries
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      createdAt
      createdBy
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: ID!, $input: CompanyInput!) {
    updateCompany(id: $id, input: $input) {
      id
      name
      phone
      fax
      email
      website
      certifications
      isFunder
      industries
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      createdAt
      createdBy
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: ID!) {
    deleteCompany(id: $id) {
      id
      name
      phone
      fax
      email
      website
      certifications
      isFunder
      industries
      contactTypes
      specialist
      categories
      country
      province
      county
      city
      street
      street2
      postal
      status
      notes {
        username
        sourceId
        parent
        id
        createdAt
        body
      }
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      createdAt
      createdBy
      updatedAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;
