import { gql } from "@apollo/client";

export const GET_PROJECTS = gql`
  query getAllProjects($nextToken: String) {
    getAllProjects(nextToken: $nextToken) {
      projects {
        approvedBy
        code
        approvedDate
        budget {
          createdAt
          id
          sourceId
          expenses
          adminFees
          budget
          file
          createdAt
        }
        categories
        claims {
          username
          date
          claimNumber
          sourceId
          paid
          id
        }
        tagLine
        status
        startDate
        program
        notes {
          username
          sourceId
          parent
          id
          createdAt
        }
        name
        members
        lead
        industries
        id
        funder
        dueDate
        description
        department
        deliverables {
          username
          status
          sourceId
          responsible
          name
          id

          dueDate
          createdAt
        }
        createdAt
        coops
        contacts
        username
      }
      nextToken
    }
  }
`;

export const GET_PROJECT_BY_ID = gql`
  query GetProjectById($id: ID!) {
    getProjectById(id: $id) {
      id
      name
      tagLine
      code
      funder
      program
      department
      coops
      lead
      members
      status
      contacts
      username
      approvedBy
      approvedDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id
        dueDate
        createdAt
      }
      description
      budget {
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const CREATE_PROJECT = gql`
  mutation CreateProject($newProject: ProjectInput) {
    createProject(newProject: $newProject) {
      id
      name
      tagLine
      funder
      program
      department
      coops
      lead
      members
      status
      contacts
      username
      approvedBy
      approvedDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id
        dueDate
        createdAt
      }
      description
      budget {
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UpdateProject($id: ID!, $input: ProjectInput) {
    updateProject(id: $id, input: $input) {
      id
      name
      tagLine
      funder
      program
      department
      coops
      lead
      members
      status
      contacts
      username
      approvedBy
      approvedDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id
        dueDate
        createdAt
      }
      description
      budget {
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const DELETE_PROJECT = gql`
  mutation DeleteProject($id: ID!) {
    deleteProject(id: $id) {
      id
      name
      tagLine
      funder
      program
      department
      coops
      lead
      members
      status
      contacts
      username
      approvedBy
      approvedDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id
        dueDate
        createdAt
      }
      description
      budget {
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;
