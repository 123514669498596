import { gql } from "@apollo/client";

export const GET_SERVICES = gql`
  query getAllServices($nextToken: String) {
    getAllServices(nextToken: $nextToken) {
      services {
        id
        name
        tagLine
        type
        program
        department
        code
        company
        lead
        members
        status
        contacts
        approvedBy
        approvedByDate
        startDate
        dueDate
        industries
        categories
        notes {
          id
          sourceId
          username
          body
          parent
          createdAt
        }
        deliverables {
          id
          sourceId
          name
          responsible
          dueDate
          status
          username
          createdAt
        }
        description
        qoutedAmount
        invoicedAmount
        invoices {
          id
          sourceId
          date
          invoiceNumber
          paid
          username
          createdAt
        }
        paymentTerm
        downPayment
        paymentAlert
        hasNda
        username
        createdAt
      }
      nextToken
    }
  }
`;

export const GET_SERVICE_BY_ID = gql`
  query getServiceById($id: ID!) {
    getServiceById(id: $id) {
      id
      name
      tagLine
      type
      program
      department
      code
      company
      lead
      members
      status
      contacts
      approvedBy
      approvedByDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        id
        sourceId
        name
        responsible
        dueDate
        status
        username
        createdAt
      }
      description
      qoutedAmount
      invoicedAmount
      invoices {
        id
        sourceId
        date
        invoiceNumber
        paid
        username
        createdAt
      }
      paymentTerm
      downPayment
      paymentAlert
      hasNda
      username
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const CREATE_SERVICE = gql`
  mutation CreateService($newService: ServiceInput) {
    createService(newService: $newService) {
      id
      name
      tagLine
      type
      program
      department
      code
      company
      lead
      members
      status
      contacts
      approvedBy
      approvedByDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        id
        sourceId
        name
        responsible
        dueDate
        status
        username
        createdAt
      }
      description
      qoutedAmount
      invoicedAmount
      invoices {
        id
        sourceId
        date
        invoiceNumber
        paid
        username
        createdAt
      }
      paymentTerm
      downPayment
      paymentAlert
      hasNda
      username
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const UPDATE_SERVICE = gql`
  mutation UpdateService($id: ID!, $input: ServiceInput) {
    updateService(id: $id, input: $input) {
      id
      name
      tagLine
      type
      program
      department
      code
      company
      lead
      members
      status
      contacts
      approvedBy
      approvedByDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        id
        sourceId
        name
        responsible
        dueDate
        status
        username
        createdAt
      }
      description
      qoutedAmount
      invoicedAmount
      invoices {
        id
        sourceId
        date
        invoiceNumber
        paid
        username
        createdAt
      }
      paymentTerm
      downPayment
      paymentAlert
      hasNda
      username
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const DELETE_SERVICE = gql`
  mutation DeleteService($id: ID!) {
    deleteService(id: $id) {
      id
      name
      tagLine
      type
      program
      department
      code
      company
      lead
      members
      status
      contacts
      approvedBy
      approvedByDate
      startDate
      dueDate
      industries
      categories
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        id
        sourceId
        name
        responsible
        dueDate
        status
        username
        createdAt
      }
      description
      qoutedAmount
      invoicedAmount
      invoices {
        id
        sourceId
        date
        invoiceNumber
        paid
        username
        createdAt
      }
      paymentTerm
      downPayment
      paymentAlert
      hasNda
      username
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;
