import React, { useState } from "react";
import { CognitoIdentityProvider } from "@aws-sdk/client-cognito-identity-provider";
import { Auth } from "aws-amplify";
import { QRCodeCanvas } from "qrcode.react";
import { toast } from "react-toastify";
import awsmobile from "../../aws-exports";
import { LoginBtn, ForgotPassword, AdvancedInput } from "../../components";

const Login = () => {
  const [mfa, setMFA] = useState(false);
  const [user, setUser] = useState(null);
  const [qrcode, setQRCode] = useState("");
  const [showQRCode, setShowQRCode] = useState(false);
  const [email, setEmail] = useState("");
  const [userCode, setUserCode] = useState("");
  const [password, setPassword] = useState("");
  const [clicked, setClicked] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [newDeviceSetup, setNewDeviceSetup] = useState(false);
  const varificationIsEmpty = userCode.length < 6 || userCode.length > 6;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const user = await Auth.signIn(email, password);
      console.log("user pre re-auth", user);
      if (user.preferredMFA === "NOMFA") {
        setUser(user);
        const code = await Auth.setupTOTP(user);
        const codeURL = `otpauth://totp/Perennia CRM:${user.username}?secret=${code}&issuer=Perennia CRM`;
        setMFA(false);
        setUserCode("");
        setQRCode(codeURL);
        setShowQRCode(true);
      } else {
        setUser(user);
        setMFA(true);
      }
      setClicked(true);
    } catch (error) {
      console.log("Failed to login", error);
      toast.error("Failed to login. Please try again.");
    }
  };

  const handleNewDeviceSetup = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const cognitoClient = new CognitoIdentityProvider({
        region: awsmobile.aws_cognito_region,
        credentials: Auth.essentialCredentials(user),
      });

      const params = {
        ChallengeName: "SMS_MFA",
        ClientId: awsmobile.aws_user_pools_web_client_id,
        Session: user.Session,
        ChallengeResponses: {
          USERNAME: email,
          SMS_MFA_CODE: userCode,
        },
      };

      const response = await cognitoClient.respondToAuthChallenge(params);
      console.log("response after SMS", response);

      setUser({ ...user, Session: response.Session });

      setNewDeviceSetup(false);
      setMFA(false);
      setUserCode("");

      Auth.signIn(email, password);

      console.log("user after 2nd sign in attempt", user);
      // window.location.href = "/";
    } catch (error) {
      console.log("Failed to verify SMS", error);
      toast.error("OOPS! Something went wrong. Please try again.");
    }
  };

  const handleCodeSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!/^\d{6}$/.test(userCode)) {
      alert("Validation Code must contain 6 numbers");
      return;
    }
    console.log("user after response", user);
    try {
      if (user.challengeName === "SMS_MFA") {
        await verifySMS();
      } else if (user.challengeName === "SOFTWARE_TOKEN_MFA") {
        await verifyTOTP();
      } else {
        console.log("Failed to verify MFA");
        toast.error(
          "We're having trouble verifying your account. Please try again."
        );
      }
    } catch (error) {
      console.log("Failed to verify TOTP", error);
      toast.error("OOPS! Something went wrong. Please try again.");
    }
  };

  const handleTOTPInitialSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await Auth.verifyTotpToken(user, userCode);
      await Auth.setPreferredMFA(user, "TOTP");
      window.location.href = "/";
    } catch (error) {
      console.log("Failed to verify TOTP", error);
      toast.error("OOPS! Something went wrong. Please try again.");
    }
  };

  const verifySMS = async () => {
    try {
      const confirmedUser = await Auth.confirmSignIn(user, userCode, "SMS_MFA");
      setUser(confirmedUser);
      // User needs to setup TOTP after first login with SMS
      const code = await Auth.setupTOTP(user);
      const codeURL = `otpauth://totp/Perennia CRM:${user.username}?secret=${code}&issuer=Perennia CRM`;
      setMFA(false);
      setUserCode("");
      setQRCode(codeURL);
      setShowQRCode(true);
    } catch (error) {
      console.log("Failed to verify SMS", error);
      toast.error("OOPS! Something went wrong. Please try again.");
    }
  };

  const verifyTOTP = async () => {
    try {
      await Auth.confirmSignIn(user, userCode, "SOFTWARE_TOKEN_MFA");
      setMFA(false);
      setUserCode("");
      window.location.href = "/";
    } catch (error) {
      console.log("Failed to verify TOTP", error);
      toast.error("OOPS! Something went wrong. Please try again.");
    }
  };

  return (
    <div className="login-container" data-testid="login">
      {forgotPassword && <ForgotPassword key="forgotPw" />}
      <div className="app-version">{process.env.REACT_APP_VERSION_NUMBER}</div>
      <div
        className="login-left-container"
        data-testid="login-splash"
        style={{
          backgroundImage: `url(/images/loginsplash.jpg)`,
        }}
      />
      <div className="login-right-container">
        <img
          className="main-logo"
          src={process.env.PUBLIC_URL + "/images/perennia-logo.png"}
          alt="Perennia"
          aria-label="Perennia Food and Agriculture"
          data-testid="login-main-logo"
        />
        <h2>Perennia CRM</h2>
        {mfa ? (
          <div className="MFA-items-container">
            <p>Please enter your verification code below</p>
            <form
              onSubmit={
                newDeviceSetup ? handleNewDeviceSetup : handleCodeSubmit
              }
            >
              <AdvancedInput
                type="text"
                width="225px"
                name="Temporary 6-digit code"
                value={userCode}
                onChange={(e) => setUserCode(e.target.value)}
              />
              <LoginBtn
                disabled={varificationIsEmpty}
                text={clicked ? "Confirm Code" : "waiting..."}
              />
            </form>
          </div>
        ) : showQRCode ? (
          <div className="MFA-items-container">
            {user.preferredMFA === "NOMFA" ? (
              <p>It appears you have recently changed devices.</p>
            ) : (
              <p>
                Due to recent updates, we are now requiring all users to setup
                <br />
                Two-Factor Authentication (2FA) to access the CRM.
                <br />
              </p>
            )}
            <p>Please scan the QR code below with your Authenticator app</p>
            <QRCodeCanvas value={qrcode} />
            <br />
            <form onSubmit={handleTOTPInitialSubmit}>
              <AdvancedInput
                type="text"
                width="225px"
                name="Temporary 6-digit code"
                value={userCode}
                onChange={(e) => setUserCode(e.target.value)}
              />
              <LoginBtn
                disabled={varificationIsEmpty}
                text={clicked ? "Confirm Code" : "waiting..."}
              />
            </form>
          </div>
        ) : (
          <React.Fragment>
            <p>Welcome back! Please login with your provided credentials</p>
            <form
              className="login-form"
              onSubmit={handleSubmit}
              data-testid="login-form"
            >
              <label htmlFor="email">Username</label>
              <input
                className="login-input"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <label htmlFor="password">Password</label>
              <input
                className="login-input"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p
                className="forgot-pw"
                onClick={() => setForgotPassword(!forgotPassword)}
              >
                Forgot Password?
              </p>
              <LoginBtn text={clicked ? "waiting..." : "Login"} />
            </form>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default Login;
