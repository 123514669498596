/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { SubmitButton } from "../../theme";
import { Editor, AdvancedInput } from "..";
import { Status } from "../../utilities";
import * as FaIcons from "react-icons/fa";
import { DataContext } from "../../context/DataContext";
import ItemUpdate from "../item-updates/ItemUpdate";

const Address = (props: any) => {
  const Data = props.data;
  const { companies } = React.useContext(DataContext);
  const [companyLink, setCompanyLink] = useState(null);
  const [companyName, setCompanyName] = useState(null);
  const [editStatus, setEditStatus] = useState(false);
  const [status, setStatus] = useState("");

  useEffect(() => {
    if (props.type === "contact") {
      companies.map((item) => {
        if (Data?.companyName === item.id) {
          setCompanyLink(item.id);
          setCompanyName(item.name);
        }
      });
    } else return;
  });

  const handleStatusUpdate = (e) => {
    e.preventDefault();
    props.handleStatusUpdate(status);
    setEditStatus(!editStatus);
  };

  return (
    <div className="address-card" data-testid="address">
      <div className="address-main-info">
        {props.type === "contact" && (
          <>
            {Data?.isPrimary && (
              <div className="address-contact-isPrimary">
                <p>Primary Contact</p>
              </div>
            )}
            <p className="address-contact-name">
              <span className="contact-title"> {Data?.title} </span>
              {Data?.name}
            </p>
            <p className="address-companyName">
              <Link to={`/directory/companies/${companyLink}`}>
                {companyName}
              </Link>
            </p>
            <p>
              Division: <span> {Data?.division} </span>
            </p>
            <p>
              Phone Number:
              <span>
                <a href={`tel://${Data?.phone}`}>{Data?.phone}</a>
              </span>
              Ext:
              <span>{Data?.ext}</span>
            </p>
          </>
        )}
        {props.type === "company" && (
          <>
            {Data?.isFunder && (
              <div className="company-isFunder">
                <p>Funder</p>
              </div>
            )}
            <p>
              Phone Number:
              <span>
                <a href={`tel://${Data?.phone}`}>{Data?.phone}</a>
              </span>
              Ext:
              <span>{Data?.extension}</span>
            </p>
            <p>
              Fax:
              <span>
                <a href={`tel://${Data?.fax}`}>{Data?.fax}</a>
              </span>
            </p>
            <p>
              Primary Email:
              <span>
                <a href={`mailto://${Data?.email}`}>{Data?.email}</a>
              </span>
            </p>
            <p>
              Website:
              <span>
                <a
                  href={`http://${Data?.website}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {Data?.website}
                </a>
              </span>
            </p>
          </>
        )}
      </div>
      <div className="address">
        <p>
          Street:<span> {Data?.street} </span>
        </p>
        <p>
          City:<span> {Data?.city} </span>
        </p>
        <p>
          Postal Code/Zip:<span> {Data?.postal} </span>
        </p>
        <p>
          County:<span> {Data?.county} </span>
        </p>
        <p>
          Province/State:<span> {Data?.province} </span>
        </p>
        <p>
          Country:<span> {Data?.country} </span>
        </p>
      </div>
      {props.type === "contact" && (
        <div className="address-emails">
          <p>
            Primary Email:
            <span>
              <a href={`mailto://${Data?.primaryEmail}`}>
                {Data?.primaryEmail}
              </a>
            </span>
          </p>
          <p>
            Secondary Email:
            <span>
              <a href={`mailto://${Data?.secondaryEmail}`}>
                {Data?.secondaryEmail}
              </a>
            </span>
          </p>
        </div>
      )}
      <div className="address-phone">
        <p>
          Primary Number:
          <span>
            <a href={`tel://${Data?.phone}`}>{Data?.phone}</a>
          </span>
        </p>
        {props.type === "contact" && (
          <p>
            Mobile:
            <span>
              <a href={`tel://${Data?.mobile}`}>{Data?.mobile}</a>
            </span>
          </p>
        )}
        <p>
          Fax:
          <span>
            <a href={`tel://${Data?.fax}`}>{Data?.fax}</a>
          </span>
        </p>
      </div>
      <div className="address-created-info">
        <div className="status">
          <p>
            Status:
            <span>{Data?.status}</span>
          </p>
          {props.canEdit && (
            <button onClick={() => setEditStatus(!editStatus)}>
              <FaIcons.FaEdit />
            </button>
          )}
        </div>
        {editStatus && (
          <Editor
            type="Status"
            component={[
              <form className="add-item-form">
                <AdvancedInput
                  {...props}
                  name="Status"
                  type="dropdown"
                  width="95%"
                  fieldName="name"
                  data={Status}
                  value={status}
                  onChange={(e) => setStatus(e.name)}
                />
                <div className="add-item-button-container">
                  <div />
                  <SubmitButton width="150px" onClick={handleStatusUpdate}>
                    Submit
                  </SubmitButton>
                </div>
              </form>,
            ]}
          />
        )}
        <p>
          Created on: <span> {Data?.createdAt.substr(0, 10)} </span>
        </p>
        <ItemUpdate data={Data} isManager={props.isManager} />
      </div>
    </div>
  );
};

export default Address;
