export const INITIAL_PROGRAM_STATE = () => {
  return {
    name: "",
    tagLine: "",
    description: "",
    code: "",
    funder: [],
    contacts: [],
    lead: [],
    members: [],
    industries: [],
    startDate: "",
    dueDate: "",
    status: "",
    interactions: [],
    notes: [],
    deliverables: [],
    budget: [],
    claims: [],
    updates: [],
  };
};

export const ProgramReducer = (state, action) => {
  switch (action.field) {
    case "name":
    case "tagLine":
    case "description":
    case "code":
    case "funder":
    case "contacts":
    case "lead":
    case "members":
    case "industries":
    case "startDate":
    case "dueDate":
    case "status":
    case "interactions":
    case "notes":
    case "deliverables":
    case "budget":
    case "claims":
    case "updates":
      return {
        ...state,
        [action.field]: action.payload,
      };
    default:
      return;
  }
};
