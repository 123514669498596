import React, { useState, useEffect, useContext } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { PageBody, PageGrid } from "../../theme";
import { ProgramReducer } from "../../utilities";
import { DataContext } from "../../context/DataContext";
import {
  GET_PROGRAM_BY_ID,
  UPDATE_PROGRAM,
  CREATE_CLAIM,
  UPDATE_CLAIM,
  DELETE_CLAIM,
} from "../../api";
import {
  AddProgram,
  ActivePRS,
  ContactToPRS,
  Deliverables,
  Editor,
  InfoCard,
  ProgramOverview,
  Notes,
  ClaimsAndInvoices,
  Tags,
  Budgets,
  InteractionToSingleItem,
} from "../../components";

const Program = (props: any) => {
  const { id } = useParams();
  const { projects, services, resources } = useContext(DataContext);
  const [program, setProgram] = useState(null);
  const [created, setCreated] = useState("");
  const [editor, setEditor] = useState(false);
  const [managerStatus, setManagerStatus] = useState(false);
  const [updateStatus] = useMutation(UPDATE_PROGRAM);
  const { data: programData } = useQuery(GET_PROGRAM_BY_ID, {
    variables: { id },
  });

  useEffect(() => {
    if (programData) {
      setProgram(programData?.getProgramById);
      setCreated(program?.createdAt.substr(0, 10));
    }
  }, [programData, program?.createdAt]);

  useEffect(() => {
    if (
      props?.user.attributes["custom:userGroup"] === "Uber User" ||
      props?.user.attributes["custom:userGroup"] === "Finance"
    ) {
      setManagerStatus(true);
    }
  }, [props?.user]);

  const handleEdit = (e) => {
    e.preventDefault();
    setEditor(!editor);
  };

  const handleStatusUpdate = (status) => {
    updateStatus({
      variables: {
        id,
        input: {
          status: status,
        },
      },
      refetchQueries: [{ query: GET_PROGRAM_BY_ID }],
    });
  };

  console.log("program contacts", program?.contacts);

  return (
    <>
      {editor && (
        <Editor
          type="Program"
          component={
            <AddProgram {...props} isEdit={true} id={id} data={program} />
          }
        />
      )}
      <PageBody>
        <div className="last-modified">
          <p>
            Last Modified:
            <span>{created}</span>
          </p>
        </div>
        <PageGrid>
          <div className="item-left-column">
            <InfoCard
              title="Overview"
              height="650px"
              width="100%"
              headerOption={managerStatus ? "edit" : null}
              onBtnClick={handleEdit}
              fields={[
                <ProgramOverview
                  data={program}
                  type="program"
                  handleStatusUpdate={handleStatusUpdate}
                  isManager={managerStatus}
                  managerPermission={managerStatus}
                />,
              ]}
            />
            <InfoCard
              title="Tags"
              height="300px"
              width="100%"
              fields={[<Tags type="program" data={program} />]}
            />
            <div
              className="grid-block"
              style={{ height: "300px", margin: "25px" }}
            />
            {managerStatus && (
              <div
                className="grid-block"
                style={{ height: "300px", margin: "25px" }}
              />
            )}
          </div>
          <div className="item-middle-column">
            <InfoCard
              title="Main Funder Contacts"
              height="300px"
              width="100%"
              fields={[<ContactToPRS data={program?.contacts} />]}
            />
            <InfoCard
              title="Projects"
              height="300px"
              width="100%"
              fields={[
                <ActivePRS
                  data={projects}
                  type="projects"
                  id={id}
                  pairType="program"
                />,
              ]}
            />
            <InfoCard
              title="Services"
              height="300px"
              width="100%"
              fields={[
                <ActivePRS
                  data={services}
                  type="services"
                  id={id}
                  pairType="program"
                />,
              ]}
            />
            <InfoCard
              title="Resources"
              height="300px"
              width="100%"
              fields={[
                <ActivePRS
                  data={resources}
                  type="resources"
                  id={id}
                  pairType="program"
                />,
              ]}
            />
            {managerStatus && (
              <InfoCard
                title="Budget"
                height="300px"
                width="100%"
                fields={[
                  <Budgets
                    key="program-budget"
                    id={id}
                    type="budget"
                    field="budget"
                    data={program?.budget}
                    user={props?.user}
                    refetchQuery={GET_PROGRAM_BY_ID}
                  />,
                ]}
              />
            )}
          </div>
          <div className="item-right-column">
            <InfoCard
              title="Notes"
              height="650px"
              width="100%"
              fields={[
                <Notes
                  id={id}
                  data={program?.notes}
                  reducer={ProgramReducer}
                  refetchQuery={GET_PROGRAM_BY_ID}
                />,
              ]}
            />
            <InfoCard
              title="Interactions"
              height="300px"
              width="100%"
              fields={[
                <InteractionToSingleItem id={id} type="program/project" />,
              ]}
            />
            <InfoCard
              title="Deliverables"
              height="300px"
              width="100%"
              fields={[
                <Deliverables
                  key="program-deliverables"
                  id={id}
                  type="project"
                  field="deliverables"
                  data={program?.deliverables}
                />,
              ]}
            />
            {managerStatus && (
              <InfoCard
                title="Claims"
                height="300px"
                width="100%"
                fields={[
                  <ClaimsAndInvoices
                    id={id}
                    type="program"
                    field="claim"
                    data={program?.claims}
                    createMutation={CREATE_CLAIM}
                    updateMutation={UPDATE_CLAIM}
                    deleteMutation={DELETE_CLAIM}
                  />,
                ]}
              />
            )}
          </div>
        </PageGrid>
      </PageBody>
    </>
  );
};

export default Program;
