/* eslint-disable array-callback-return */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Editor, AdvancedInput } from "..";
import { Status } from "../../utilities";
import { SubmitButton } from "../../theme";
import { DataContext } from "../../context/DataContext";
import ItemUpdate from "../item-updates/ItemUpdate";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";

const ProjectsAndServicesOverview = (props: any) => {
  const Data = props.data;
  const { programs, companies, departments, users } =
    React.useContext(DataContext);
  const [program, setProgram] = useState(null);
  const [funders, setFunders] = useState([]);
  const [coops, setCoops] = useState([]);
  const [lead, setLead] = useState([]);
  const [members, setMembers] = useState([]);
  const [editStatus, setEditStatus] = useState(false);
  const [status, setStatus] = useState("");
  const [teams, setTeams] = useState([]);

  // Get Related Pairings
  // Funder & Companies
  useEffect(() => {
    if (props.type === "service") {
      const linkedCompanies = companies.filter((item) => {
        if (Data?.company.includes(item.id)) return item;
      });
      setFunders(linkedCompanies);
    } else if (props.type === "project") {
      const linkedCoop = companies.filter((item) => {
        if (Data?.coops.includes(item.id)) return item;
      });
      setCoops(linkedCoop);
      const linkedFunders = companies.filter((item) => {
        if (item?.id.includes(Data?.funder)) return item;
      });
      setFunders(linkedFunders);
    }
  }, [props.type, companies, Data?.company, Data?.coops, Data?.funder]);

  // Users and Members
  useEffect(() => {
    const linkedLead = users.filter((item) => {
      if (Data?.lead.includes(item.id)) return item;
    });
    setLead(linkedLead);

    const linkedMembers = users.filter((item) => {
      if (Data?.members.includes(item.id)) return item;
    });
    setMembers(linkedMembers);
  }, [users, Data?.lead, Data?.members]);

  // Misc Info
  useEffect(() => {
    const linkedProgram = programs.find((item) => {
      if (Data?.program.includes(item.id)) return item;
    });
    setProgram(linkedProgram);

    const linkedTeams = departments.filter((item) => {
      if (Data?.department.includes(item.id)) return item;
    });
    setTeams(linkedTeams);
  }, [programs, departments, Data?.program, Data?.department]);

  const handleStatusUpdate = (e) => {
    e.preventDefault();
    props.handleStatusUpdate(status);
    setEditStatus(!editStatus);
  };

  return (
    <div className="item-overview">
      <div className="item-main-info">
        <p className="item-name">{Data?.name}</p>
        <p>
          Tagline:
          <span>{Data?.tagLine}</span>
        </p>
        <ul>
          {props.type} Code:
          <li>{Data?.code}</li>
        </ul>
        <ul>
          Teams:
          {teams?.map((team) => (
            <li key={team.id}>{team.name}</li>
          ))}
        </ul>
        <ul>
          Program:
          <li>
            <Link to={`/directory/programs/${program?.id}`}>
              {program?.name}
            </Link>
          </li>
        </ul>
        <ul>
          {props.type === "service" ? "Company:" : "Funder:"}
          {funders?.map((item) => (
            <li key={item.id}>
              <Link to={`/directory/companies/${item?.id}`}>{item?.name}</Link>
            </li>
          ))}
        </ul>
        {props.type === "project" && (
          <ul>
            Co-operators:
            {coops?.map((item) => (
              <li key={item.id}>
                <Link to={`/directory/companies/${item?.id}`}>
                  {item?.name}
                </Link>
              </li>
            ))}
          </ul>
        )}
        <ul>
          Lead:
          {lead?.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
        <ul>
          Members:
          {members?.map((item) => (
            <li key={item.id}>{item.name}</li>
          ))}
        </ul>
        {props.type === "service" && props.hasNDA ? (
          <ul>
            Description:
            <li>{Data?.description}</li>
          </ul>
        ) : props.type === "project" ? (
          <ul>
            Description:
            <li>{Data?.description}</li>
          </ul>
        ) : null}
      </div>
      <div className="item-dates-info">
        <div className="status">
          <p>
            Status:
            <span>{Data?.status}</span>
          </p>
          {props.managerPermission && (
            <button onClick={() => setEditStatus(!editStatus)}>
              <FaIcons.FaEdit />
            </button>
          )}
        </div>
        {editStatus && (
          <Editor
            type="Status"
            component={[
              <form className="add-item-form">
                <AdvancedInput
                  {...props}
                  name="Status"
                  type="dropdown"
                  width="95%"
                  fieldName="name"
                  data={Status}
                  value={status}
                  onChange={(e) => setStatus(e.name)}
                />
                <div className="add-item-button-container">
                  <div />
                  <SubmitButton width="150px" onClick={handleStatusUpdate}>
                    Submit
                  </SubmitButton>
                </div>
              </form>,
            ]}
          />
        )}
        {props.type === "service" && (
          <p>
            Date Signed:
            <span>{Data?.approvedByDate}</span>
          </p>
        )}
        <p>
          Start Date:
          <span>{Data?.startDate}</span>
        </p>
        <p>
          Due Date:
          <span>{Data?.dueDate}</span>
        </p>
        {props.type === "service" && props.hasNDA ? (
          <>
            <p>
              Quoted Amount:
              <span>${Data?.qoutedAmount}</span>
            </p>
            <p>
              Down payment: <span>{Data?.paymentTerm}</span>
            </p>
            <p>
              Down payment Paid:{" "}
              <span
                className={
                  Data?.downpayment === true ? "dp-paid" : "dp-not-paid"
                }
              >
                {Data?.downPayment === true ? (
                  <FaIcons.FaCheckCircle />
                ) : (
                  <AiIcons.AiFillCloseCircle />
                )}
              </span>
            </p>
          </>
        ) : null}
        <ItemUpdate data={Data} isManager={props.isManager} />
      </div>
    </div>
  );
};

export default ProjectsAndServicesOverview;
