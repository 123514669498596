import { gql } from "@apollo/client";

export const GET_ALL_PROGRAMS = gql`
  query GetAllPrograms($nextToken: String) {
    getAllPrograms(nextToken: $nextToken) {
      programs {
        id
        name
        tagLine
        description
        code
        funder
        contacts
        lead
        members
        industries
        startDate
        dueDate
        status
        interactions {
          id
          sourceId
          username
          body
          actionType
          category
          contact
          createdAt
        }
        notes {
          id
          sourceId
          username
          body
          parent
          createdAt
        }
        deliverables {
          username
          status
          sourceId
          responsible
          name
          id

          dueDate
          createdAt
        }
        budget {
          id
          id
          sourceId
          expenses
          adminFees
          budget
          file
          createdAt
        }
        claims {
          username
          id
          sourceId
          date
          claimNumber
          paid
        }
        createdAt
      }
      nextToken
    }
  }
`;

export const GET_PROGRAM_BY_ID = gql`
  query getProgramById($id: ID!) {
    getProgramById(id: $id) {
      id
      name
      tagLine
      description
      code
      funder
      contacts
      lead
      members
      industries
      startDate
      dueDate
      status
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id

        dueDate
        createdAt
      }
      budget {
        id
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const CREATE_PROGRAM = gql`
  mutation CreateProgram($newProgram: ProgramInput) {
    createProgram(newProgram: $newProgram) {
      id
      name
      tagLine
      description
      code
      funder
      contacts
      lead
      members
      industries
      startDate
      dueDate
      status
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id
        dueDate
        createdAt
      }
      budget {
        id
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const UPDATE_PROGRAM = gql`
  mutation UpdateProgram($id: ID!, $input: ProgramInput) {
    updateProgram(id: $id, input: $input) {
      id
      name
      tagLine
      description
      code
      funder
      contacts
      lead
      members
      industries
      startDate
      dueDate
      status
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id
        dueDate
        createdAt
      }
      budget {
        id
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;

export const DELETE_PROGRAM = gql`
  mutation DeleteProgram($id: ID!) {
    deleteProgram(id: $id) {
      id
      name
      tagLine
      description
      code
      funder
      contacts
      lead
      members
      industries
      startDate
      dueDate
      status
      interactions {
        id
        sourceId
        username
        body
        actionType
        category
        contact
        createdAt
      }
      notes {
        id
        sourceId
        username
        body
        parent
        createdAt
      }
      deliverables {
        username
        status
        sourceId
        responsible
        name
        id
        dueDate
        createdAt
      }
      budget {
        id
        id
        sourceId
        expenses
        adminFees
        budget
        file
        createdAt
      }
      claims {
        username
        id
        sourceId
        date
        claimNumber
        paid
      }
      createdAt
      updates {
        id
        sourceId
        userId
        createdAt
      }
    }
  }
`;
