/* eslint-disable array-callback-return */
/* eslint-disable no-useless-escape */
import React, { useState, useReducer, Reducer, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, SubmitButton } from "../../../theme";
import { AdvancedInput, CategorySelect, DeleteItem } from "../../";
import { CompanyReducer, INITIAL_COMPANY_STATE } from "../../../utilities";
import { DataContext } from "../../../context/DataContext";
import {
  CREATE_COMPANY,
  UPDATE_COMPANY,
  GET_COMPANIES,
  DELETE_COMPANY,
  CREATE_UPDATE,
} from "../../../api";

const AddCompany = (props: any) => {
  const id = props.id;
  const editData = props.data;
  const navigate = useNavigate();
  const {
    companies,
    certifications,
    contactTypes,
    categories,
    industries,
    users,
  } = React.useContext(DataContext);
  const [createNewCompany] = useMutation(CREATE_COMPANY);
  const [updateCompany] = useMutation(UPDATE_COMPANY);
  const [createUpdate] = useMutation(CREATE_UPDATE);
  const [stage, setStage] = useState(1);
  const [input, setInput] = useState(null);
  const [error, setError] = useState(null);
  const [successfullSubmit, setSuccessfullSubmit] = useState(false);
  const [state, dispatch] = useReducer<Reducer<any, any>, any>(
    CompanyReducer,
    INITIAL_COMPANY_STATE(),
    undefined
  );

  useEffect(() => {
    const getInputState = async () => setInput(await state);
    getInputState();
  }, [state]);

  const handlePrevStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage - 1);
  };

  const handleNextStage = (e) => {
    e.preventDefault();
    setStage((stage) => stage + 1);
  };

  const handleValidation = () => {
    let formIsValid = true;
    let errors = {};

    if (!props.isEdit && !input.name) {
      formIsValid = false;
      errors["name"] = "name can not be empty";
    }
    if (!props.isEdit) {
      companies?.find((item) => {
        if (item.name?.includes(input.name)) {
          formIsValid = false;
          errors["name"] = "Name already exists.";
        }
      });
    }
    setError({ errors: errors });
    return formIsValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (handleValidation()) {
      if (props.isEdit) {
        const updatedInputs = JSON.parse(
          JSON.stringify(input, (key, value) => {
            if (Array.isArray(value) && value.length === 0) {
              return undefined;
            } else if (value === null || value === "") {
              return undefined;
            } else if (typeof value === "boolean" || value) {
              return value;
            } else {
              return undefined;
            }
          })
        );
        updateCompany({
          variables: {
            id,
            input: {
              ...updatedInputs,
              status: "Pending",
              updatedAt: new Date().toISOString(),
            },
          },
          refetchQueries: [
            { query: GET_COMPANIES, fetchPolicy: "network-only" },
          ],
        });
        createUpdate({
          variables: {
            sourceId: id,
            newUpdate: {
              userId: props.user?.attributes.sub,
            },
          },
        });
        toast.success("Company has successfully been updated");
        setSuccessfullSubmit(true);
      } else {
        createNewCompany({
          variables: {
            newCompany: {
              ...input,
              status: "Pending",
              createdBy: props.user?.attributes["custom:fullName"],
            },
          },
          refetchQueries: [
            { query: GET_COMPANIES, fetchPolicy: "network-only" },
          ],
        });
        toast.success(
          "Company has successfully been added. You can now close the window"
        );
        setSuccessfullSubmit(true);
        if (
          window.confirm(
            "Company has successfully been added. Would you like to create a contact?"
          )
        ) {
          navigate("/directory/contacts");
        }
      }
    } else {
      toast.error(
        "OOPS! that didn't work. Please verify the information and try again"
      );
      setStage(1);
    }
  };

  return (
    <form className="add-item-form" onSubmit={handleSubmit}>
      {stage === 1 && (
        <>
          <AdvancedInput
            {...props}
            name="company name"
            type="text"
            width="95%"
            isRequired={props.isEdit ? false : true}
            placeholder={editData?.name}
            value={state.name}
            onChange={(e) => {
              dispatch({ field: "name", payload: e.target.value });
            }}
          />
          <span className="error-text">{error?.errors.name}</span>
          <AdvancedInput
            {...props}
            name="website"
            type="text"
            width="95%"
            placeholder={editData?.website}
            value={state.website}
            onChange={(e) =>
              dispatch({ field: "website", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="email"
            type="email"
            width="95%"
            placeholder={editData?.email}
            value={state.email}
            onChange={(e) =>
              dispatch({ field: "email", payload: e.target.value })
            }
          />
          <span className="error-text">{error?.errors.email}</span>
          <AdvancedInput
            {...props}
            name="office phone"
            type="phone"
            hasExt
            width="95%"
            placeholder={editData?.phone}
            value={state.phone}
            onChange={(e) => {
              dispatch({ field: "phone", payload: e.target.value });
            }}
            extValue={state.ext}
            extOnChange={(e) => {
              dispatch({ field: "ext", payload: e.target.value });
            }}
          />
          <span className="error-text">{error?.errors.phone}</span>
          <span className="error-text">{error?.errors.ext}</span>
          <AdvancedInput
            name="fax number"
            type="phone"
            width="95%"
            placeholder={editData?.fax}
            value={state.fax}
            onChange={(e) => {
              dispatch({ field: "fax", payload: e.target.value });
            }}
          />
          <AdvancedInput
            {...props}
            name="Certifcations / Licenses"
            data={certifications}
            fieldName="name"
            type="dropdown"
            width="95%"
            isMulti
            value={state.certifications}
            onChange={(selectedOptions) =>
              dispatch({
                field: "certifications",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="funder"
            type="select"
            placeholder={editData?.isFunder}
            value={state.isFunder}
            checked={state.isFunder}
            onChange={() => dispatch({ field: "isFunder" })}
          />
          ,
        </>
      )}
      {stage === 2 && (
        <>
          <AdvancedInput
            {...props}
            name="street"
            type="text"
            width="95%"
            placeholder={editData?.street}
            value={state.street}
            onChange={(e) =>
              dispatch({ field: "street", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="street 2"
            type="text"
            width="95%"
            placeholder={editData?.street2}
            value={state.street2}
            onChange={(e) =>
              dispatch({ field: "street2", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="city"
            type="text"
            width="95%"
            placeholder={editData?.city}
            value={state.city}
            onChange={(e) =>
              dispatch({ field: "city", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="county"
            type="text"
            width="95%"
            placeholder={editData?.county}
            value={state.county}
            onChange={(e) =>
              dispatch({ field: "county", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Province/State"
            type="text"
            width="95%"
            placeholder={editData?.province}
            value={state.province}
            onChange={(e) =>
              dispatch({ field: "province", payload: e.target.value })
            }
          />
          <AdvancedInput
            {...props}
            name="Country"
            type="countries"
            fieldName="name"
            width="95%"
            placeholder={editData?.country}
            value={state.country}
            onChange={(e) => dispatch({ field: "country", payload: e.name })}
          />
          <AdvancedInput
            {...props}
            name="postal code / zip"
            type="text"
            width="95%"
            placeholder={editData?.postal}
            value={state.postal}
            onChange={(e) =>
              dispatch({ field: "postal", payload: e.target.value })
            }
          />
        </>
      )}
      {stage === 3 && (
        <>
          <AdvancedInput
            {...props}
            name="contact type"
            data={contactTypes}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.contactTypes}
            onChange={(selectedOptions) =>
              dispatch({
                field: "contactTypes",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <AdvancedInput
            {...props}
            name="industry"
            data={industries}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.industries}
            onChange={(selectedOptions) =>
              dispatch({
                field: "industries",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
          <CategorySelect
            data={categories}
            value={state.categories}
            handleOnChange={(selectedCategories) =>
              dispatch({
                field: "categories",
                payload: selectedCategories,
              })
            }
          />
          <AdvancedInput
            {...props}
            name="specialist"
            data={users}
            isMulti
            fieldName="name"
            type="dropdown"
            width="95%"
            value={state.specialist}
            onChange={(selectedOptions) =>
              dispatch({
                field: "specialist",
                payload: selectedOptions.map((i) => i.id),
              })
            }
          />
        </>
      )}
      <div className="add-item-button-container">
        {stage === 1 ? (
          <div style={{ width: "150px" }} />
        ) : (
          <Button width="150px" onClick={handlePrevStage}>
            Go Back
          </Button>
        )}
        {stage !== 3 ? (
          <Button width="150px" onClick={handleNextStage}>
            Next
          </Button>
        ) : successfullSubmit ? (
          <Button width="150px" disabled={successfullSubmit}>
            Success
          </Button>
        ) : (
          <SubmitButton width="150px" disabled={successfullSubmit}>
            Submit
          </SubmitButton>
        )}
      </div>
      {stage === 3 && props.isEdit ? (
        <DeleteItem
          id={id}
          user={props.user}
          mutation={DELETE_COMPANY}
          refetch={GET_COMPANIES}
          type="Company"
        />
      ) : null}
    </form>
  );
};

export default AddCompany;
